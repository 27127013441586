<template>
  <div>
    <head-layout
      :head-btn-options="headBtnOptions"
      head-title="题库基本信息"
      @head-cancel="headCancel"
    ></head-layout>
    <form-layout
      ref="formLayout"
      :column="formColumn"
      :dataForm.sync="dataForm"
    ></form-layout>
    <head-layout
      head-title="题目选项明细"
    ></head-layout>
    <grid-layout
      ref="gridLayOut"
      :table-options="tableOptions"
      :table-data="tableData"
      :table-loading="tableLoading"
    >
    </grid-layout>
  </div>
</template>

<script>

import HeadLayout from "@/views/components/layout/head-layout";
import FormLayout from "@/views/components/layout/form-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import {getEduTree} from "@/api/training/edutree";
import {getDetail} from "@/api/training/eduquestions";
import {getLineList} from "@/api/training/eduquestionsanswers";

export default {
  name: "eduquestionsView",
  components: {
    HeadLayout,
    FormLayout,
    GridLayout,
  },
  data() {
    return {
      type: "",
      dataForm: {},
      tableData: [],
      tableLoading: false,
      query: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
    }
  },
  computed: {
    headBtnOptions() {
      return [
        {
          label: this.$t('cip.cmn.btn.cancel'),
          emit: "head-cancel",
          type: "button",
          icon: "",
          btnOptType: 'cancel',
        }
      ]
    },
    formColumn() {
      return [
        {
          label: "编号",
          prop: "code",
          slot: true,
          search: true,
          sortable: true,
          readonly: true,
          width: 180,
          placeholder: "保存后自动生成",
          rules: [{
            required: false,
            message: "请输入编号",
            trigger: ["blur", "change"]
          }]
        },
        {
          label: "分类",
          prop: "subjectType",
          sortable: true,
          dicData: [],
          type: "tree",
          props: {
            label: "title",
            value: "value",
          },
          disabled: true,
          rules: [{
            required: true,
            message: "请输入分类",
            trigger: ["blur", "change"]
          }]
        },
        // {
        //   label: "出题依据",
        //   prop: "accordingTo",
        //   sortable: true,
        //   search: true,
        //   type: 'select',
        //   dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=EDUCATION_ACCORDING_TO',
        //   props: {
        //     label: 'dictValue',
        //     value: 'dictKey',
        //   },
        //   disabled: true,
        //   rules: [{
        //     required: true,
        //     message: "请输入出题依据",
        //     trigger: ["blur", "change"]
        //   }]
        // },
        // {
        //   label: "考试级别",
        //   prop: "lexaminationLevel",
        //   sortable: true,
        //   search: true,
        //   type: 'select',
        //   dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=EDUCATION_EXAMINATION_LEVEL',
        //   props: {
        //     label: 'dictValue',
        //     value: 'dictKey',
        //   },
        //   disabled: true,
        //   rules: [{
        //     required: true,
        //     message: "请输入考试级别",
        //     trigger: ["blur", "change"]
        //   }]
        // },
        {
          label: "难易度",
          prop: "facilityType",
          sortable: true,
          search: true,
          type: 'select',
          dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=exam_difficult_level',
          props: {
            label: 'dictValue',
            value: 'dictKey',
          },
          disabled: true,
          rules: [{
            required: true,
            message: "请输入难易度",
            trigger: ["blur", "change"]
          }]
        },
        {
          label: "题目类型",
          prop: "topicType",
          sortable: true,
          search: true,
          type: 'select',
          dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=exam_question_type',
          props: {
            label: 'dictValue',
            value: 'dictKey',
          },
          disabled: true,
          value: '1',
          rules: [{
            required: true,
            message: "请输入题目类型",
            trigger: ["blur", "change"]
          }]
        },
        {
          label: "题目名称",
          prop: "subjectName",
          type: "textarea",
          search: true,
          sortable: true,
          maxlength: 150,
          span: 24,
          minRows: "2",
          maxRows: "4",
          showWordLimit: true,
          rules: [{
            required: true,
            message: "请输入题目名称",
            trigger: ["blur", "change"]
          }]
        },
        {
          label: "题目解析",
          prop: "analysis",
          sortable: true,
          type: "textarea",
          overHidden: true,
          span: 24,
          minRows: "2",
          maxRows: "4",
          maxlength: 500,
          showWordLimit: true,
          readonly: true,
        },
        {
          label: "备注",
          prop: "note",
          sortable: true,
          type: "textarea",
          overHidden: true,
          span: 24,
          minRows: "2",
          maxRows: "4",
          maxlength: 100,
          showWordLimit: true,
          readonly: true,
          rules: [{
            required: false,
            message: "请输入备注",
            trigger: ["blur", "change"]
          }]
        },
      ]
    },
    tableOptions() {
      return {
        menu: false,
        selection: false,
        column: [
          {
            label: "选项",
            prop: "answerOption",
            sortable: true,
            //readonly: true,
            maxlength: 20,
            showWordLimit: true,
            width: 250,
            rules: [{
              required: true,
              message: "请输入选项",
              trigger: ["blur", "change"]
            }]
          },
          {
            label: "选项值",
            prop: "answerOptionValue",
            sortable: true,
            maxlength: 100,
            showWordLimit: true,
            minWidth: 250,
            rules: [{
              required: true,
              message: "请输入选项值",
              trigger: ["blur", "change"]
            }]
          },
          {
            label: "是否正确答案",
            prop: "answerRight",
            sortable: true,
            type: "select",
            dicUrl: '/api/sinoma-system/dict/dictionary?code=yes_no',
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
            width: 150,
            align: 'center',
            dataType: "number",
            rules: [{
              required: true,
              message: "请输入是否正确答案",
              trigger: ["blur", "change"]
            }]
          },
        ],
      }
    },
  },
  methods: {
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    initData() {
      getEduTree().then(res => {
        const column = this.findObject(this.$refs.formLayout.option.column, 'subjectType')
        column.dicData = res.data.data
      })
    },
    formData(id) {
      getDetail(id).then((res) => {
        this.dataForm = this.$refs.formLayout.dataForm = res.data.data;
      });
    },
    onLoad(page, params = {}) {
      this.tableLoading = true;
      getLineList(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.query)
      ).then((res) => {
        const data = res.data.data;
        this.page.total = data.total;
        this.tableData = data.records
        this.tableLoading = false;
        this.$refs.gridLayOut.selectionClear();
      });
    },
  },
  mounted() {
    this.initData()
  },
  created() {
    if (this.$route.query.row) {
      let row = JSON.parse(this.$route.query.row);
      this.dataForm.id = row.id;
    }
    this.type = this.$route.query.type;
    if (this.type == "add") {
      this.dataForm.subjectType = this.$route.query.subjectType;
      this.dataForm.id = "";
    } else if (this.type == "edit") {
      this.query.questionId = this.dataForm.id;
      this.formData(this.dataForm.id);
      this.onLoad(this.page)
    } else if (this.type == "view") {
      this.query.questionId = this.dataForm.id;
      this.formData(this.dataForm.id);
      this.onLoad(this.page)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .avue-crud .el-table {
  height: 396px !important;
  max-height: 396px !important;
}
</style>
